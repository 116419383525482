import React from "react";
import "../assets/scss/header.scss";
import { List, X } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Gtags from "./gTags";
import { MetaData } from "../data/metaTags";
import { menuItems } from "../Constant/Constant";
 

const Header = () => {
  const openMenu = () => {
    $(".mobile-menu").css({ transform: "translateX(0)" });
  };

  const title = window.location.pathname.toLowerCase().startsWith("/join")
    ? "/join"
    : window.location.pathname.toLowerCase();
  const isKey = MetaData.hasOwnProperty(title);

  const closeMenu = () => {
    $(".mobile-menu").css({ transform: "translateX(100%)" });
  };

  return (
    <>
      <Gtags
        title={isKey ? MetaData[title].title : "ERROR 404 | RedBeryl™"}
        meta={
          isKey
            ? {
                description: MetaData[title].description,
                keywords: MetaData[title].keywords,
              }
            : "Page Not Found"
        }
      />
      <header className="header">
        <Link className="mobile-center" to="/">
          <LazyLoadImage
            effect="blur"
            src="https://redberyl.co/images%20only/logoWhite.svg"
            alt="logo"
            className="redBeryl-icon"
          />
        </Link>
        <div className="menu">
          <List size={32} color="#fff" onClick={openMenu} />
        </div>

        <div className="mobile-menu desktop-menu">
          <ul className="pages">
            {menuItems.map((menuItem, index) => (
              <a key={index} href={menuItem.link}>
                <li onClick={closeMenu}>{menuItem.label}</li>
              </a>
            ))}
            <li className="nav-icon" onClick={closeMenu}>
              <X size={28} />
            </li>
          </ul>
        </div>
      </header>
      <div className="margin-bottom"></div>
    </>
  );
};

export default Header;
